<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        For each of the reaction steps below, select the appropriate purpose for the step in the
        synthesis of 2-butoxynaphthalene from 2-naphthol and 1-bromobutane.
      </p>

      <p class="mb-5" v-for="question in questions" :key="question.input">
        <stemble-latex :content="question.text" class="mb-2" />
        <v-select
          v-model="inputs[question.input]"
          class="ml-3"
          style="max-width: 700px"
          dense
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51lbA4Q7',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      items: [
        {
          text: 'Prevent ethanol from bumping violently when heated.',
          value: 'preventBumping',
        },
        {
          text: 'Deprotonate the alcohol to become a good nucleophile.',
          value: 'makeGoodNucleophile',
        },
        {
          text: 'Avoid boiling away solvent in an open environment.',
          value: 'avoidBoilingSolvent',
        },
        {
          text: 'Isolate the ether product by leveraging its low solubility in water and ability to be isolated by precipitation.',
          value: 'isolateEther',
        },
        {
          text: 'Get an accurate weight of the solid by making sure it is completely dry.',
          value: 'getAccurateWeight',
        },
      ],
      questions: [
        {
          text: 'a) Boiling chips or a stir bar are added to the round bottom flask prior to the reaction being heated under reflux.',
          input: 'input1',
        },
        {
          text: 'b) Solid sodium hydroxide is added to a 2-naphthol with heating.',
          input: 'input2',
        },
        {
          text: 'c) The flask is fitted with a condenser and heated for twenty minutes.',
          input: 'input3',
        },
        {
          text: 'd) The contents of the reaction flask are poured into ice.',
          input: 'input4',
        },
        {
          text: 'e) The residue is not removed from the Büchner filter immediately after filtration.',
          input: 'input5',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    itemsShuffled() {
      return seededShuffle(this.items, this.seed);
    },
  },
};
</script>
